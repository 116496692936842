<div class="col-md col-ms col-lg" style="padding: 5px 25px;width: 100%;min-height: 70vh;display: inline-block;">
  <fab-panel [isOpen]="isPanelOpen" (onDismiss)="closePanel()" [type]="panelType" [headerText]="panelHeaderText" [isBlocking]="false" [closeButtonAriaLabel]="'Close'">
    <div style="padding: 10px">
      <solution-view-container *ngFor="let sol of panelSolutions" [solutionTitle]="sol.Title" [detector]="'solutionorchestrator'"
        [isAnalysisView]="false" [isKeystoneDetector]="false" [showFeedbackQuestion]="false">
      <markdown [data]="sol.DescriptionMarkdown"></markdown>
      </solution-view-container>
    </div>
  </fab-panel>
  <div *ngIf="!inDrillDownMode">
    <ng-container *ngTemplateOutlet="detectorTimePickerPill"></ng-container>
  </div>
  <div *ngIf="timePickerErrorStr !==''" class="error-time-picker-message">{{timePickerErrorStr}}</div>
  <div class="row" *ngIf="!inDrillDownMode" class="centered-view">
    <div class="row" name="search-bar" style="margin: 20px auto;">
      <div style="margin: auto 25%;">
        <div class="search-box">
          <div role="search" class="vanilla-search-box" [style.border]="searchBoxInFocus? '1px solid rgb(0, 120, 212)': ''">
            <div class="vanilla-search-icon-container" [style.width]="hideSearchIcon? '0px': ''">
              <fab-icon *ngIf="!hideSearchIcon" [iconName]="'Search'" aria-hidden="true"></fab-icon>
            </div>
            <input class="vanilla-search-box-input" id="fabSearchBox-solutionOrchestrator" type="search" [placeholder]="searchPlaceHolder" [ngModel]="searchTermDisplayed"
              [attr.aria-label]="inputAriaLabel" (ngModelChange)="updateSearchTerm($event)" (focus)="onSearchBoxFocus()"
              (keydown.escape)="clearSearchTerm()" (keyup.enter)="onSearchEnter()"/>
          </div>
        </div>
      </div>
    </div>
    <div class="row" name="top-solution" style="border-bottom: 1px solid #ccc;padding-bottom:15px;">
      <div style="width: 75%; margin: auto;margin-bottom:60px;" [ngStyle]="{'margin-bottom': (fetchingGPTResults && !gptSolutionHasTimedout) || newSearchTriggered? '60px': 'auto'}">
        <div *ngIf="(fetchingGPTResults && !gptSolutionHasTimedout) || newSearchTriggered" style="width: 60%;margin: 15px 20%;min-width: 300px;background-color: white;">
          <loader-detector-view></loader-detector-view>
        </div>
        <div *ngIf="!fetchingGPTResults && showGPTSolution && gptSolution && gptSolution.length > 2" tabindex="0">
          <solution-view-container [solutionTitle]="'The below answer may help you'" [isRecommended]="false" [detector]="'detector'"
            [isAnalysisView]="false" [isKeystoneDetector]="false" [askReasonNotHelpful]="false" [metadataForLogging]="feedbackLoggingData" [showThanksMessage]="showThanksMessage" (showThanksMessageChange)="showThanksMessageChanged($event)">
            <p [innerText]="gptSolution"></p>
          </solution-view-container>
        </div>
      </div>
    </div>
    <div class="row" name="detector-results">
      <collapsible-list-fabric [iconProps]="observationSectionIcon" *ngIf="issueDetectedViewModels.length>0" [lessMargin]="true" [collapsed]="!(issueDetectedViewModels.length>0 || detectorSolutionsTimedout)" [title]="'Observations and solutions (' + issueDetectedViewModels.length + ')'">
        <collapsible-list-item body *ngFor="let viewModel of issueDetectedViewModels;let i =index" style="display: block; width: 90%; margin: 5px 2.5%;">
          <div class="insight-box">
            <div style="display: block;">
              <status-icon style="display: inline-block;" [loading]="viewModel.model.loadingStatus" [status]="viewModel.model.status"
                [size]="16">
              </status-icon>
              <div class="insight-detector-name">{{viewModel.model.title}}</div>
            </div>
            <div class="insight-message">{{viewModel.insightTitle}}</div>
            <div style="display: block;margin-left: 31px;">
              <fab-primary-button [styles]="solutionButtonStyle" *ngIf="viewModel.solutions && viewModel.solutions.length>0"
                  type="Submit" text='View solutions' (click)="showSolutions(viewModel)">
              </fab-primary-button>
              <fab-link [href]="''" [styles]="viewModel.solutions && viewModel.solutions.length>0? linkStyle: successfulLinkStyle" (click)="viewDetectorData(viewModel, 'Observations and solutions')">View supporting data</fab-link>
            </div>
          </div>
        </collapsible-list-item>
      </collapsible-list-fabric>
      <div *ngIf="getPendingDetectorCount() > 0" style="margin-top: 5px;padding: 0px 10px;border-bottom: 1px solid #ccc;">
        <div style="display: inline;" class="row">
          <i class="fa fa-circle-o-notch fa-spin spin-icon" style="display:inline;float:left; vertical-align:text-bottom;" aria-hidden="true"></i>
          <div class="sections-loading-title col-md-5" style="display: inline;">Running Diagnostic checks</div>
          <div class="sections-loading-title col-md-3" style="display: inline;">Progress {{detectors.length-getPendingDetectorCount()}}/{{detectors.length}}</div>
        </div>
      </div>
      <collapsible-list-fabric [iconProps]="successfulSectionIcon" *ngIf="successfulViewModels.length>0 && getPendingDetectorCount() === 0" (collapsedChange)="successfulSectionCollapsedChanged($event)" [collapsed]="successfulSectionCollapsed" [lessMargin]="true" [title]="'Successful Checks (' + successfulViewModels.length + ')'">
        <collapsible-list-item body *ngFor="let viewModel of successfulViewModels;let i =index" style="display: block; width: 90%; margin: 5px 2.5%;">
          <div class="insight-box">
            <div style="display: block;">
              <status-icon style="display: inline-block;" [loading]="viewModel.model.loadingStatus" [status]="viewModel.model.status"
                [size]="20">
              </status-icon>
              <div class="insight-detector-name">{{viewModel.model.title}}</div>
            </div>
            <div class="insight-message">{{viewModel.insightTitle}}</div>
            <div style="display: block;margin-left: 31px;">
              <fab-link [href]="''" [styles]="successfulLinkStyle" (click)="viewDetectorData(viewModel, 'Successful checks')">View supporting data</fab-link>
            </div>
          </div>
        </collapsible-list-item>
      </collapsible-list-fabric>
    </div>
    <div class="row" name="documentation-results">
      <collapsible-list-fabric [iconProps]="documentationSectionIcon" (collapsedChange)="articleSectionCollapsedChanged($event)" [collapsed]="articleSectionCollapsed" [title]="'View Documentation and Guides from the Web (' + 2 + ')'" [lessMargin]="true">
        <collapsible-list-item body *ngIf="supportDocumentContent && supportDocumentContent.length>0" style="margin-left:25px;"><p style="font-weight: 500;">Azure Guides</p></collapsible-list-item>
        <collapsible-list-item body *ngIf="supportDocumentContent && supportDocumentContent.length>0" style="display:inline-block; width: 90%; margin: 5px 2.5%;">
          <div class="support-doc-section">
            <markdown *ngIf="supportDocumentContent.length>0" [data]="supportDocumentContent"></markdown>
          </div>
        </collapsible-list-item>
        <collapsible-list-item body style="margin-left:25px;">
          <div>
            <i *ngIf="documentsShowLoader" class="fa fa-circle-o-notch fa-spin spin-icon" aria-hidden="true"></i>
            <span style="margin-left:5px;font-weight:500;">Documents from the web</span>
          </div>
        </collapsible-list-item>
        <collapsible-list-item body *ngFor="let doc of webDocuments;let i =index" style="display:inline-block; width: 90%; margin: 5px 2.5%;">
          <div class="article" (click)="selectResult(doc)" tabindex="0" (keyup.enter)="selectResult(doc)" >
              <a style="font-weight:600;">{{doc.title}}
              <i class="fa fa-external-link"></i>
              </a>
              <div class="article-link">{{doc.linkShort}}</div>
              <div class="web-doc-text">{{doc.description}}</div>
          </div>
        </collapsible-list-item>
      </collapsible-list-fabric>
    </div>
  </div>
  <div style="display: inline-block;margin-left: 19px; margin-top: 10px;" *ngIf="inDrillDownMode">
    <a tabindex="0" (keyup.enter)="goBackToOrchestrator()" aria-label="Link to go back to the solutions view" role="button"
      (click)="goBackToOrchestrator()" >Go back to main screen</a>
  </div>
  <div *ngIf="inDrillDownMode" class="wide-view">
    <router-outlet></router-outlet>
  </div>
</div>
<div *ngIf="searchMode !== 1" class="cxp-chat-launcher-container align-width">
  <cxp-chat-launcher *ngIf="showChatButton()" [trackingId]="cxpChatTrackingId" [chatUrl]="cxpChatUrl"
    [supportTopicId]="supportTopicId"></cxp-chat-launcher>
</div>

<ng-template #detectorTimePickerPill>
  <div>
    <fab-primary-button [id]="'time-picker-button'" [styles]="buttonStyle" (onClick)="openTimePickerSubject.next(true);">
      <fab-icon [iconName]="'Clock'" [styles]="iconStyles"></fab-icon>
      <span class="ml-3 control-pill-text">
        <span>Time Range (UTC): </span>
        {{timePickerButtonStr}}
      </span>
    </fab-primary-button>
  </div>
</ng-template>

<detector-time-picker [target]="'#time-picker-button'" [openTimePickerCalloutObservable]="openTimePickerSubject" (updateTimerErrorMessage)="updateTimePickerErrorMessage($event)">
</detector-time-picker>