<div class="timepicker" id="timePicker">
  <fab-callout [hidden]="!openTimePickerCallout" [setInitialFocus]="true" [target]="target"
    (onDismiss)="cancelTimeRange()" [isBeakVisible]="false">
    <div  *ngIf="openTimePickerCallout" style="width: 320px;">
      <div class="callout-padding">
        <fab-choice-group [options]="choiceGroupOptions" [defaultSelectedKey]="selectedKey">
        </fab-choice-group>
        <div class="row">
          <div class="date-time-picker-row">
            <date-time-picker [label]="'Start'" [disabled]="disableDateAndTimePicker" [moment]="startMoment" [minMoment]="minMoment" [maxMoment]="maxMoment" (momentChange)="onChangeStartMoment($event)"></date-time-picker>
            <div class="date-picker-label">(UTC)</div>
          </div>
          <div class="date-time-picker-row">
            <date-time-picker [label]="'End'" [disabled]="disableDateAndTimePicker" [(moment)]="endMoment" [minMoment]="minMoment" [maxMoment]="maxMoment" (onEnterPressInTime)="applyTimeRange()" (onTabKeyPressInTime)="validateStartAndEndTime()"></date-time-picker>
            <div class="date-picker-label">(UTC)</div>
          </div>
        </div>
        <div *ngIf="timeDiffError != ''" style="color:red;">{{timeDiffError}}</div>
      </div>
      <div style="border-top: 1px solid gray;" class="pt-3 pb-3 callout-padding">
        <div class="mr-4">
          <fab-primary-button (onClick)="applyTimeRange()" [contentClass]="'mr-3'">Apply
          </fab-primary-button>
          <fab-default-button (onClick)="cancelTimeRange()">Cancel</fab-default-button>
        </div>
      </div>

    </div>
  </fab-callout>
</div>
