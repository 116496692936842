<applens-header></applens-header>
<div class="content-under-header">
    <div class="resource-error-container">
        <div>
            <p class="resource-error-message">{{errorMessage}}</p>
            <p>Please <strong>go back</strong> to AppLens home page and try again with your resource. If the
                issue persists, please contact <a class="help-link" tabindex=0
                    href="mailto:applensv2team@microsoft.com?Subject=AppLens%20Resource%20Validation%20Error&body=Issue%20description%3A"
                    target="_top">AppLens Team</a></p>
        </div>
    </div>