<fab-panel [isOpen]="isOpen" [type]="panelType" [customWidth]="width" [isHiddenOnDismiss]="true" [isLightDismiss]="true"
  [hasCloseButton]="true" [closeButtonAriaLabel]="'close'" (onDismiss)="dismissPanel()" [className]="'solutionPanelComponentClass'">
  <div class="solutions-panel-container panel-padding">
    <div class="solution-section solution-head">
      <h3>{{title}}</h3>
    </div>
    
    <div *ngIf="!isPublic" class="solution-section">
      <fab-message-bar [messageBarType]="messageBarType" [dismissable]="false" [styles]="messageBarStyles">Please copy
        the following instructions to external customers.</fab-message-bar>
    </div>
    
    <div class="solution-section">
      <div *ngFor="let solution of solutions,let i = index" style="margin-bottom: 16px;">
        <h4 class="solution-option-title" *ngIf="solutions.length > 1"><strong>Option -- {{i + 1}}</strong></h4>
        <solution [data]="solution"></solution>
      </div>
    </div>
  </div>
</fab-panel>