<!-- This is the default template for the header, but you can pass in a custom header as well -->
<ng-template #defaultHeader>
  <span attr.aria-label="{{title}}">{{title}}</span>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<!-- <div *ngIf="title || headerTemplate || !hideIfNoTitle else nobox" [class.data-container-card]="applicationInsightContainerStyle === 0"
  [class.data-container-card-appinsight]="applicationInsightContainerStyle === 1" [class.data-container-card-appinsight-analysis]="applicationInsightContainerStyle === 2">
    <div *ngIf="title || headerTemplate" [class.data-container-header]="applicationInsightContainerStyle === 0"
      [class.data-container-header-appinsight]="applicationInsightContainerStyle === 1" [class.data-container-header-appinsight-analysis]="applicationInsightContainerStyle === 2">
      <ng-container *ngTemplateOutlet="headerTemplate ? headerTemplate : defaultHeader">
      </ng-container>
    </div>
    <div *ngIf="description" class="text-muted card-description" [class.with-top-border]="!title">
      <markdown-text [markdownData]="description"></markdown-text>
    </div>
    <div class="panel-body" [class.data-container-body]="applicationInsightContainerStyle === 0" [class.data-container-body-appinsight]="applicationInsightContainerStyle === 1"
      [class.no-padding]="noBodyPadding" [class.with-top-border]="!title && !description">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
</div> -->

<fab-card *ngIf="title || headerTemplate || !hideIfNoTitle else nobox" [isExpandable]="false" [hideBorder]="applicationInsightContainerStyle !== 0">
  <div header>
    <div *ngIf="title || headerTemplate" [class.data-container-header]="applicationInsightContainerStyle === 0"
      [class.data-container-header-appinsight]="applicationInsightContainerStyle === 1"
      [class.data-container-header-appinsight-analysis]="applicationInsightContainerStyle === 2">
      <ng-container *ngTemplateOutlet="headerTemplate ? headerTemplate : defaultHeader">
      </ng-container>
    </div>
  </div>
  <div body>
    <div *ngIf="description" class="text-muted card-description" [class.with-top-border]="!title">
      <markdown-text [markdownData]="description"></markdown-text>
    </div>
    <div class="panel-body" [class.data-container-body]="applicationInsightContainerStyle === 0"
      [class.data-container-body-appinsight]="applicationInsightContainerStyle === 1" [class.no-padding]="noBodyPadding"
      [class.with-top-border]="!title && !description">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
</fab-card>



<ng-template #nobox>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>