<ng-container *ngFor="let slice of slices">
  <ng-container *ngIf="slice.tooltip">
    <!--
    <fab-tooltip-host
      [id]="slice.id"
      [content]="slice.tooltip"
      [directionalHint]="directionalHint" [tooltipOptions]="toolTipOptionsValue"
      (onTooltipToggle)="onTooltipToggle($event)">
      <span style="text-decoration: underline rgb(108, 201, 241) dotted;">{{slice.value}}
        <img src="assets/img/bot-sparkle.svg" style="height: 14px; width: 14px;" />
      </span>
    </fab-tooltip-host>
  -->
    <span [id]="slice.id" class="slice" (click)="onTooltipToggle(slice)">{{slice.value}}
      <img src="assets/img/enhance.svg" class="slice-icon" />
      <img src="assets/img/enhance-checked.svg" class="slice-icon-hovered" />
    </span>
    <fab-coachmark *ngIf="showCoachmark && slice.showCoachmark"
      [target]="'#' + slice.id"
      (onAnimationOpenEnd)="showTooltip(slice)"
      [positioningContainerProps]="coachmarkPositioningContainerProps"
      ariaAlertText="A coachmark has appeared"
      ariaDescribedBy="coachmark-desc1"
      ariaLabelledBy="coachmark-label1"
      ariaDescribedByText="Press enter or alt + C to open the coachmark notification"
      ariaLabelledByText="Coachmark notification">
    </fab-coachmark>
    <fab-teachingbubble #tip *ngIf="slice.visible"
      [footerContent]= "footer"
      (onDismiss)="dismissTooltip(slice)"
      [target]="'#' + slice.id"
      [hasCloseIcon]="true"
      [calloutProps]="teachingBubbleCalloutProps"
      closeButtonAriaLabel="Close"
      hasCondensedHeadline="true"
      ariaDescribedBy="openai-description1"
      [ariaLabelledBy]="'teachingbubble-' + slice.id">
      {{slice.tooltip}}
    </fab-teachingbubble>
  </ng-container>
  <ng-container *ngIf="!slice.tooltip">
    {{slice.value}}
    <span *ngIf="slice.enhance">
      <img src="assets/img/enhance.svg" style="position: relative; top: -0.4em; height: 1em; width: 1em;" />
    </span>
  </ng-container>
</ng-container>