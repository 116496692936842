<ng-template #header>
  {{renderingProperties.title}}
</ng-template>

<!-- Legacy DropDown -->
<div *ngIf="dropdownType == Type.Legacy; else fabricDropdown">
  <data-container [headerTemplate]="header" [title]="renderingProperties.title">
    <div>
      <div class="label-container">
        <label for="dropdownMenuId" tabindex="0" [attr.aria-label]="label" role="text">{{label}}</label>
      </div>
      <div class="dropdown-container">
        <div class="dropdown">
          <button class="btn btn-default dropdown-toggle dropdown-button" type="button" id="dropdownMenuId"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span [innerHtml]="selectedKey"></span>
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuId">
            <li *ngFor="let key of keys">
              <a href="#" (click)="selectKey(key,$event)">
                <span [innerHtml]="key"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  
    <hr class="line-break" />
  
    <div class="body-container">
      <div *ngFor="let data of selectedData" class="dynamic-data-container">
        <dynamic-data [diagnosticData]="data" [startTime]="startTime" [endTime]="endTime" [xAxisPlotBands]="xAxisPlotBands" 
        [zoomBehavior] = "zoomBehavior" (XAxisSelection)="onXAxisSelection($event)" [isAnalysisView]="isAnalysisView"></dynamic-data>
      </div>
    </div>
  
  </data-container>
</div>

<!-- Fabric Dropdown -->
<ng-template #fabricDropdown>
  <div class="fabric-dropdown-container">
    <div class="fabric-dropdown-header" style="display: flex;" [ngStyle]="{'justify-content': dropdownPostion == Position.FloatRight ? 'space-between' : 'start'}">
      <span class="fabric-dropdown-title">{{renderingProperties.title}}</span>
      <div style="display: flex">
        <fab-dropdown [styles]="styles" [label]="label" [options]="options" (onChange)="selectFabricKey($event)" [defaultSelectedKey]="selectedKey"
        [ariaLabel]="renderingProperties.title"></fab-dropdown>
      </div>
    </div>
    <div class="fabric-body-container">
      <div *ngFor="let data of selectedData" class="dynamic-data-container">
        <dynamic-data [diagnosticData]="data" [startTime]="startTime" [endTime]="endTime"  [xAxisPlotBands]="xAxisPlotBands" 
        [zoomBehavior] = "zoomBehavior" (XAxisSelection)="onXAxisSelection($event)" [isAnalysisView]="isAnalysisView"></dynamic-data>
      </div>
    </div>
  </div>
</ng-template>

