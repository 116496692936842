<div>
  <fab-panel [isOpen]="globals.openFeedback" [type]="type" [customWidth]="panelWidth" [isHiddenOnDismiss]="true"
    [hasCloseButton]="true" [closeButtonAriaLabel]="'close'" [isBlocking]="false"
    (onDismiss)="dismissedFeedbackHandler()" (onOpened)="onOpenFeedbackPanel()" [headerText]="'Feedback'"
    [isLightDismiss]="true" (onLightDismissClick)="dismissedFeedbackHandler()">
    <div class="feedback-panel-container">
      <!-- Feedback Panel Content -->
      <div class="panel-text feedback-panel-content">
        <p>Thank you for giving us feedback!</p>
        <p>Are you satisfied with your experience?</p>
        <div class="feedback-panel-icons" id="feedback-icons">
          <div *ngFor="let feedbackIcon of feedbackIcons;let i = index" (click)="setRating(i)"
            [ngClass]="{'selected-icon':rating - 1 === i}" class="feedback-icon hover-item" style="padding: 5px;"
            (keyup.enter)="setRating(i)" tabindex="0" role="button" name="feedback-icon-rating">
            <fab-icon [iconName]="feedbackIcon.id" [ariaLabel]="feedbackIcon.ariaLabel"></fab-icon>
            <div class="tooltiptext">{{feedbackIcon.text}}</div>
          </div>
        </div>
        <label for="feedback-textarea" style="font-weight: 400;">Tell us about your experience.</label>
        <textarea id="feedback-textarea" [(ngModel)]="feedbackText" tabindex="0" rows="7" cols="35" style="color:var(--bodyText); background-color: var(--bodyBackground);"></textarea>
      </div>
      <!-- Feedback Panel Footer -->
      <div class="feedback-panel-footer">
        <hr class="feedback-panel-divider">
        <div class="panel-text">
          <div *ngIf="isPublic">
            <fab-checkbox [label]="checkLabel" [ariaLabel]="checkLabel" [(checked)]="checked"></fab-checkbox>
            <div class="feedback-panel-link">
              <a href="https://privacy.microsoft.com/en-US/privacystatement" target="_blank">Privacy</a>
            </div>
          </div>
          <div>
            <fab-primary-button (onClick)="submitFeedback()" [disabled]="rating == 0 &&feedbackText === ''" [contentClass]="'feedback-submit-button'">Submit
              feedback
            </fab-primary-button>
          </div>
        </div>
      </div>

    </div>
  </fab-panel>

  <fab-panel [isOpen]="submitted" [isLightDismiss]="true" [type]="type" [customWidth]="'400px'"
    [isHiddenOnDismiss]="true" [hasCloseButton]="false" [closeButtonAriaLabel]="'close'" [styles]="submittedPanelStyles"
    [isBlocking]="false" (onDismiss)="dismissedSubmittedHandler()" (onOpened)="onOpenSubmittedPanel()"
    [className]="'submitted-panel-height'">
    <div style="padding: 5px 20px;">
      <div class="submitted-panel-text panel-text">
        <div>
          <span class="fa fa-check-circle submitted-panel-icon"></span> Feedback sent!
        </div>
        <div>{{currentTime}}</div>
      </div>
      <p>Thank you for your feedback</p>
    </div>
  </fab-panel>
</div>
