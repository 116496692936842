<div class="unauthorized-container">
    <div class="unauthorized-div">
        <img src="assets/img/unauthorized.svg" class="unauthorized-icon"/>
        <p class="unauthorized-header">Unauthorized! Its easy to gain access to AppLens.</p>
        <div style="display: flex; flex-direction: row;">
            <div style="flex: 0 1 auto;">
                <p class="unauthorized-sub-header">Request for AppLens Access based on your role</p>
                <ol>
                    <li><a href="https://coreidentity.microsoft.com/manage/Entitlement/entitlement/applenscssac-vynx" target="_blank" rel="noopener noreferrer">AppLens CSS Access</a> (for CSS Engineers and TAs only)</li>
                    <li><a href="https://myaccess/identityiq/ui/rest/redirect?rp1=/accessRequest/accessRequest.jsf&rp2=accessRequest/review?role=AppLens+Non-CSS+Access&autoSubmit=true" target="_blank" rel="noopener noreferrer">AppLens Non-CSS Access</a> (for Escalation Engineers and Internal Microsoft Teams)</li>
                </ol>
            </div>
        </div>
    </div>
</div>
