import { NgModule } from  '@angular/core';
import { MatTableModule } from  '@angular/material/table';

@NgModule({
imports: [MatTableModule],
exports: [MatTableModule],

})

export class CustomMaterialModule { }
