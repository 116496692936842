<div class="info-step step">
  <div class="info-icon" [ngSwitch]="infoStepView.infoType">
    <div class="recommendation-info" *ngSwitchCase="0">
      <svg viewBox="0 0 16 15" class="msportalfx-svg-palette-inherit" role="presentation" focusable="false" xmlns:svg="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="FxSymbol0-086" data-type="48"><g><title></title><path d="M16 13H7a2 2 0 01-.4.8 1.5 1.5 0 01-.5.6l-.7.5H3.6l-.7-.5a1.5 1.5 0 01-.5-.6A2 2 0 012 13H0v-1h2a2 2 0 01.4-.8 1.5 1.5 0 01.5-.6l.7-.5h1.8l.7.5a1.5 1.5 0 01.5.6 2 2 0 01.4.8h9zM4.5 14h.6l.5-.3.3-.5a1.3 1.3 0 00.1-.6 1.3 1.3 0 00-.1-.6l-.3-.5-.5-.3H3.9l-.5.3-.3.5a1.3 1.3 0 00-.1.6 1.3 1.3 0 00.1.6l.3.5.5.3zM16 3H7a2 2 0 01-.4.8 1.5 1.5 0 01-.5.6l-.7.5H3.6l-.7-.5a1.5 1.5 0 01-.5-.6A2 2 0 012 3H0V2h2a2 2 0 01.4-.8 1.5 1.5 0 01.5-.6l.7-.5h1.8l.7.5a1.5 1.5 0 01.5.6A2 2 0 017 2h9zM4.5 4h.6l.5-.3.3-.5a1.3 1.3 0 00.1-.7 1.3 1.3 0 00-.1-.6l-.3-.5-.5-.3H3.9l-.5.3-.3.5a1.3 1.3 0 00-.1.6 1.3 1.3 0 00.1.6l.3.5.5.3zM0 7h9a2 2 0 01.4-.8 1.5 1.5 0 01.5-.6l.7-.5h1.8l.7.5a1.5 1.5 0 01.5.6 2 2 0 01.4.8h2v1h-2a2 2 0 01-.4.8 1.5 1.5 0 01-.5.6l-.7.5h-1.8l-.7-.5a1.5 1.5 0 01-.5-.6A2 2 0 019 8H0zm12.1 1.9l.5-.3.3-.5a1.3 1.3 0 00.1-.6 1.3 1.3 0 00-.1-.6l-.3-.5-.5-.3h-1.2l-.5.3-.3.5a1.3 1.3 0 00-.1.6 1.3 1.3 0 00.1.6l.3.5.5.3h1.2z"></path></g></svg>
    </div>
    <div class="diagnostic-info" *ngSwitchCase="1">
      <svg viewBox="0 0 16 16" class="msportalfx-svg-palette-inherit" role="presentation" focusable="false" xmlns:svg="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="FxSymbol0-057" data-type="160"><g><title></title><path d="M15.1 1.7l.3.7a.9.9 0 01.3.6 2.5 2.5 0 01.2.7 2.2 2.2 0 01.1.8 5 5 0 01-.2 1.2 4.9 4.9 0 01-.4 1.1l-.7.9-.9.7-1.1.4-1.2.2h-.7l-6.3 6.2-.9.6-1 .2-1-.2a1.6 1.6 0 01-.8-.6 1.6 1.6 0 01-.6-.8 3.6 3.6 0 01-.2-1 3 3 0 01.2-1 2.4 2.4 0 01.6-.9l6.3-6.3a.4.4 0 01-.1-.3v-.4a5 5 0 01.2-1.2 4.9 4.9 0 01.4-1.1l.7-.9.9-.7 1.1-.4 1.2-.2h.8l.7.2a.9.9 0 01.6.3l.7.3L11.2 4l.8.8zM11.5 8a3.5 3.5 0 001.4-.3A4.1 4.1 0 0014 7a4.1 4.1 0 00.7-1.1 3.5 3.5 0 00.3-1.4 4.3 4.3 0 00-.2-1.1L12 6.2 9.8 4l2.8-2.8-1.1-.2a3.5 3.5 0 00-1.4.3A4.1 4.1 0 009 2a4.1 4.1 0 00-.7 1.1A3.5 3.5 0 008 4.5V5c.1.2.1.4.2.5l-6.7 6.7-.4.6a1.3 1.3 0 00-.1.6 1.3 1.3 0 00.1.6 1 1 0 00.4.5 1 1 0 00.5.4h1.2l.6-.4 6.7-6.7.5.2z"></path></g></svg>
    </div>
  </div>
  <div class="markdown-title">
      {{infoStepView.title}}
  </div>

  <div class="markdown-container">
    <div class="markdown"><markdown ngPreserveWhitespaces [data]="infoStepView.markdown"></markdown></div>
  </div>
</div>
