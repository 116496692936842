<div [class.card-border]="!hideBorder" class="card" [ngStyle]="{'background': background !=='' ? background : ''}">
  <div [class.card-align]="!isExpandable && isAlign">
    <div *ngIf="isExpandable" class="btn-outer" [attr.aria-expanded]="ariaExpaned" [attr.aria-label]="ariaLabel">
      <button *ngIf="isExpandable" class="custom-caret" [class.custom-caret-down]="!expanded" (click)="toggleExpand()">
        <fab-icon iconName="ChevronUp" [ariaLabel]="ariaLabelChevronUp"></fab-icon>
      </button>
    </div>
  </div>

  <div class="card-container">
    <div>
      <ng-content select="[header]"></ng-content>
    </div>
    <div>
      <ng-content *ngIf="expanded" select="[body]"></ng-content>
    </div>
    <div>
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>