<ng-template #emailHeader>
  <span class="fa fa-envelope"></span>
  Customer Email Template
</ng-template>

<data-container [description]="renderingProperties.description" [headerTemplate]="emailHeader">

  <div class="email-body" [innerHtml]="email">
  </div>

</data-container>