<!-- This is the common template for rendering insights.
     If there is a title I will wrap it in a container,
     otherwise I will show it by itself -->
<ng-template #insightView>
  <div *ngFor="let insight of insights">
    <fab-card [isExpandable]="hasContent(insight)" [expanded]="hasContent(insight) && insight.isExpanded" [ariaLabel]="insight.title" [background]="getInsightBackground(insight.status)">
      <div header>
        <div style="display: flex;align-items: center;min-height: 40px;">
          <status-icon class="mr-3" [status]="insight.status"></status-icon>
          <div class="mr-3"><strong><openai [text]="insight.title"></openai></strong></div>
          <div *ngIf="hasSolutions(insight) && solutionButtonPosition === SolutionButtonPosition.NextToTitle">
            <ng-container [ngTemplateOutlet]="solutionButton" [ngTemplateOutletContext]="{'insight':insight}"></ng-container>
          </div>
        </div>
      </div>

      <div body style="margin-bottom: 10px;">
        <table>
          <tbody>
            <tr *ngFor="let key of insight.getKeys()">
              <td class="table-key">
                <b>{{key}}</b>
              </td>
              <td class="table-value">
                <markdown-text [markdownData]="insight.data[key]" [detector]="detector"></markdown-text>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="hasSolutions(insight) && solutionButtonPosition === SolutionButtonPosition.Bottom" footer>
        <ng-container [ngTemplateOutlet]="solutionButton" [ngTemplateOutletContext]="{'insight':insight}"></ng-container>
      </div>
    </fab-card>
  </div>
</ng-template>

<solutions-panel [isPanelOpenObservable]="solutionPanelOpenSubject" [solutions]="solutions" [title]="solutionTitle"></solutions-panel>

<!-- If there is title wrap it in container -->
<data-container *ngIf="renderingProperties.title; else noContainer" [title]="renderingProperties.title"
  [description]="renderingProperties.description">
  <ng-container *ngTemplateOutlet="insightView"></ng-container>
</data-container>

<!-- If there is no title, do not use container. Note this is called from the else statement in the *ngIf above -->
<ng-template #noContainer>
  <ng-container *ngTemplateOutlet="insightView"></ng-container>
</ng-template>

<!-- Show as Button or Link for solution -->
<ng-template #solutionButton let-insight="insight">
  <ng-container [ngSwitch]="solutionButtonType">
    <ng-container *ngSwitchCase="SolutionButtonType.Button">
      <div style="margin-bottom: 15px;">
        <fab-primary-button (onClick)="openSolutionPanel(insight)" [contentStyle]="'border-radius: 2px;font-size: 13px;'">{{solutionButtonLabel}}</fab-primary-button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="SolutionButtonType.Link">
      <fab-link (onClick)="openSolutionPanel(insight)">{{solutionButtonLabel}}</fab-link>
    </ng-container>
  </ng-container>
</ng-template>