import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-tokeninvalid',
    templateUrl: './tokeninvalid.component.html',
    styleUrls: ['./tokeninvalid.component.scss']
  })
export class TokenInvalidComponent implements OnInit {
    ngOnInit(){
    }
}