<solution-view-container *ngIf="keystoneInsight" [solutionTitle]="keystoneInsight.Title" [isRecommended]="isRecommended"
  [detector]="detector" [isAnalysisView]="false" [isKeystoneDetector]="true">
  <div class="keystone-content">
    <markdown-text [markdownData]="keystoneInsight.Description" [detector]="detector"></markdown-text>
  </div>
  <ng-container *ngIf="keystoneSolution">
    <div class="keystone-content">
      <div class="keystone-solution-title">Suggested Solution:</div>
      <solution [data]="keystoneSolution"></solution>
    </div>
  </ng-container>
</solution-view-container>
