import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-auth-request-failed',
    templateUrl: './auth-request-failed.component.html',
    styleUrls: ['./auth-request-failed.component.scss']
  })
export class AuthRequestFailedComponent implements OnInit {
    ngOnInit(){
    }
}