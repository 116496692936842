<div>
  <div style="display: flex;align-items: center;margin-bottom: 10px;">
    <div *ngIf="allowColumnSearch" class="text-field">
      <fab-search-box #fabSearchBox (onChange)="updateSearchValue($event)" (onFocus)="focusSearchBox()" [placeholder]="searchPlaceholder"></fab-search-box>
    </div>
    <!-- Filters -->
    <div *ngIf="tableFilters.length > 0" style="display: flex;margin-top: 5px;">
      <div *ngFor="let filter of tableFilters;let i = index" style="margin-left: 16px;">
        <fab-data-table-filter [tableFilter]="filter" [options]="getOptionsWithColName(filter.name)" (onFilterUpdate)="updateFilter(filter.name,$event)" [index]="i" [tableId]="tableId"></fab-data-table-filter>
      </div>
   
    </div>
  </div>

  <div *ngIf="description" style="margin:16px 0px;">
    <markdown-text [markdownData]="description"></markdown-text>
  </div>

  <div [attr.data-is-scrollable]="true" style="overflow: auto;">
    <fab-details-list [items]="rows">
      <columns>
        <fab-details-list-column #fabDetailListColumn *ngFor="let col of columns;let colIndex=index" [key]="col.key"
          [name]="col.name" [minWidth]="col.minWidth" [maxWidth]="col.maxWidth" [isSorted]="col.isSorted"
          [isSortedDescending]="col.isSortedDescending" (onColumnClick)="clickColumn($event)"
          [isResizable]="col.isResizable" [isMultiline]="col.isMultiline" [ariaLabel]="col.name">
          <render>
            <ng-template let-item="item" let-column="column" let-rowIndex="index">
              <ng-container 
                [ngTemplateOutlet]="isMarkdown(item[column.name]) ? markdownText : embeddedHTML"
                [ngTemplateOutletContext]="{data:item[column.name]}"
              >
              </ng-container>
            </ng-template>
          </render>
        </fab-details-list-column>
      </columns>
    </fab-details-list>
  </div>
  <div *ngIf="descriptionColumnName !== '' && selectionText !== ''">
    <pre class="description-text">{{ selectionText }}</pre>
  </div>
</div>

<div *ngIf = "buttonText != ''" class = "table-button">
  <fab-primary-button [text]="buttonText" (onClick) = "returnSelectedItems()" [contentClass]="'mr-3'">
  </fab-primary-button>
</div>

<ng-template #emptyTableFooter>
  <div tabindex="0" class="table-footer">
    <p>No Data to Display</p>
  </div>
</ng-template>

<ng-template #markdownText let-data='data'>
  <markdown-text [markdownData]="data"></markdown-text>
</ng-template>

<ng-template #embeddedHTML let-data='data'>
  <div [innerHTML]="data"></div>
</ng-template>
