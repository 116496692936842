<div *ngIf="guage">
  <div *ngIf="isVertical(guage.renderDirection)">
    <div class="vertical-container">
      <div class="guage-iterator" *ngFor="let currGuage of guage.guages">
        <div class="guage-container">
          <div *ngIf="currGuage.guageGraphicElement" class="guage-graphic-container">
            <guage-graphic [guageGraphic]="currGuage.guageGraphicElement"></guage-graphic>
          </div>
          <div *ngIf="currGuage.guageDescription.trim().length > 0" class="guage-description">
            <markdown [data]="currGuage.guageDescription"></markdown>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isHorizontal(guage.renderDirection)">
    <div class="horizontal-container">
      <div class="guage-iterator" *ngFor="let currGuage of guage.guages">
        <div class="guage-container" style="flex-direction:column;">
          <div *ngIf="currGuage.guageGraphicElement" class="guage-graphic-container">
            <guage-graphic [guageGraphic]="currGuage.guageGraphicElement"></guage-graphic>
          </div>
          <div *ngIf="currGuage.guageDescription.trim().length > 0" class="guage-description">
            <markdown [data]="currGuage.guageDescription"></markdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>