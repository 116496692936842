<ng-template #header>
    {{renderingProperties.title}}
    <button class="btn btn-default markdown-button" style="float:right;margin-top: -5px" *ngIf="!isPublic" (click)="copyMarkdown()" tabindex="0" (keyup.enter)="copyMarkdown()" aria-label="copy markdown">
        <span class="fa fa-copy"></span>
    </button>
</ng-template>

<data-container *ngIf="renderingProperties.isContainerNeeded else noContainerMarkdown" [headerTemplate]="header" [title]="renderingProperties.title">
    <markdown-text [markdownData]="markdownData" [detector]="detector" [isMarkdownView]="true"></markdown-text>
</data-container>

<!-- If there is no title, do not use container. Note this is called from the else statement in the *ngIf above -->
<ng-template #noContainerMarkdown>
    <div *ngIf="renderingProperties.title">
        <h1>{{renderingProperties.title}}</h1>
    </div>
    <markdown-text [markdownData]="markdownData" [isMarkdownView]="true"></markdown-text>
</ng-template>
