<h2 *ngIf="detectorDataLocalCopy && isPublic && !isInCaseSubmission() && !hideDetectorHeader" style="display: flex;">
  <div *ngIf="!isSystemInvoker && !insideDetectorList" tabindex="0" id="detector-name">
    <span *ngIf="overWriteDetectorName && overWriteDetectorName.length > 0 ;else detectorName">
      {{overWriteDetectorName}}
    </span>
  </div>
  <div
    *ngIf="!isSystemInvoker && !insideDetectorList && !!(overWriteDetectorDescription || detectorDataLocalCopy.metadata.description)"
    class="blade-description">&nbsp;- &nbsp;</div>
  <div class="blade-description">
    <span *ngIf="overWriteDetectorDescription && overWriteDetectorDescription.length > 0;else detectorDescription">
      {{overWriteDetectorDescription}}
    </span>
  </div>
</h2>

<a *ngIf="breadCrumb && breadCrumb.name && isPublic" (click)="navigateForBreadCrumb()"
  (keyup.enter)="navigateForBreadCrumb()" tabindex="0" [attr.aria-label]="'Go Back to ' + breadCrumb.name">Go Back to
  {{breadCrumb.name}}
</a>

<div *ngIf="detectorDataLocalCopy" class="detector-control">
  <div *ngIf="!hideDetectorControl && !insideDetectorList">
    <ng-container *ngTemplateOutlet="detectorTimePickerPill"></ng-container>
  </div>
  <div *ngIf="isAnalysisView && supportsDownTime" class="ml-3">
    <fab-primary-button [id]="'detector-view-downtime'" (onClick)="showDowntimeCallout = true;" [styles]="buttonStyle">
      <fab-icon [iconName]="'Clock'" [styles]="iconStyles"></fab-icon>
      <span class="ml-3 control-pill-text">
        <span>Downtime(UTC): </span>
        {{downtimeButtonStr}}
      </span>
    </fab-primary-button>
    <fab-callout [hidden]="!showDowntimeCallout" [setInitialFocus]="true" [target]="'#detector-view-downtime'"
      (onDismiss)="closeDownTimeCallOut()">
      <div style="max-width: 300px; overflow-y: auto; padding: 16px;">
        <fab-choice-group [options]="fabChoiceGroupOptions" [selectedKey]="selectedKey"></fab-choice-group>
      </div>
      <div style="border-top: 1px solid gray; padding: 16px;">
        <fab-primary-button (onClick)="selectFabricKey()" aria-label="apply" [contentClass]="'mr-3'">Apply
        </fab-primary-button>
        <fab-default-button (onClick)="closeDownTimeCallOut()" aria-label="cancel">Cancel</fab-default-button>
      </div>
    </fab-callout>
    <div *ngIf="downtimeSelectionErrorStr!=''" class="errorMessage"
      attr.aria-label="Error : {{downtimeSelectionErrorStr}}" tabindex="0" role="text">
      <strong>Error :</strong> {{downtimeSelectionErrorStr}}<span style='vertical-align:super'>*</span>
    </div>
  </div>
</div>

<div *ngIf="detectorDataLocalCopy">
  <div *ngFor="let data of detectorDataLocalCopy.dataset  | renderfilter:isAnalysisView"
    [ngClass]="{'dynamic-data-container': !isRiskAlertDetector}">
    <dynamic-data [isRiskAlertDetector]="isRiskAlertDetector" [isAnalysisView]="isAnalysisView" [diagnosticData]="data"
      [startTime]="startTime" [endTime]="endTime" [detectorEventProperties]="detectorEventProperties"
      [developmentMode]="developmentMode" [executionScript]="script" [detector]="detector"
      [xAxisPlotBands]="xAxisPlotBands" [zoomBehavior]="zoomBehavior" (XAxisSelection)="onXAxisSelection($event)"
      [compilationPackage]="compilationPackage" [hideShieldComponent]="hideShieldComponent"></dynamic-data>
  </div>
</div>

<data-container *ngIf="!isAnalysisView && supportDocumentRendered" [title]="'Additional troubleshooting resources'">
  <markdown *ngIf="supportDocumentContent.length>0" #markdownDiv [data]="supportDocumentContent"></markdown>
</data-container>

<cxp-chat-launcher *ngIf="showChatButton()" [trackingId]="cxpChatTrackingId" [chatUrl]="cxpChatUrl"
  [supportTopicId]="supportTopicId"></cxp-chat-launcher>

<ng-container *ngIf="!detectorDataLocalCopy">
  <div class="mt-3" *ngIf="!hideDetectorControl && !insideDetectorList">
    <ng-container *ngTemplateOutlet="detectorTimePickerPill"></ng-container>
  </div>
  <ng-container *ngIf="!errorState">
    <loader-detector-view style="margin-top:300px" [Source]="'DetectorView'" [LoadingMessage1]="loadingMessage"
      [LoadingMessage2]="loadingMessage" [LoadingMessage3]="loadingMessage" [LoadingMessage4]="loadingMessage">
    </loader-detector-view>
  </ng-container>
  <ng-container *ngIf="errorState">
    <span class="critical-color" *ngIf="errorState.error && !isPublic && !forbiddenError"> Error: {{errorState.error}}</span>
    <span class="critical-color" *ngIf="isPublic && errorState || !errorState.error">Sorry, an error occurred. Please
      refresh the page and try again.</span>
  </ng-container>
</ng-container>

<ng-template #detectorName>
  <span>{{detectorDataLocalCopy.metadata.name}}</span>
</ng-template>

<ng-template #detectorDescription>
  <span>{{detectorDataLocalCopy.metadata.description}}</span>
</ng-template>

<ng-template #detectorTimePickerPill>
  <div>
    <fab-primary-button [id]="'time-picker-button'" [styles]="buttonStyle"
      (onClick)="openTimePickerSubject.next(true);">
      <fab-icon [iconName]="'Clock'" [styles]="iconStyles"></fab-icon>
      <span class="ml-3 control-pill-text">
        <span>Time Range (UTC): </span>
        {{timePickerButtonStr}}
      </span>
    </fab-primary-button>
  </div>
  <div *ngIf="timePickerErrorStr !==''" class="mt-3 error-time-picker-message">{{timePickerErrorStr}}</div>
</ng-template>

<detector-time-picker [target]="'#time-picker-button'" [openTimePickerCalloutObservable]="openTimePickerSubject"
  (updateTimerErrorMessage)="updateTimePickerErrorMessage($event)">
</detector-time-picker>